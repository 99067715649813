import { graphql, useStaticQuery } from 'gatsby';

export const useBlogQuery = () => {
  const { allSanityBlogPost } = useStaticQuery(graphql`
    query {
      allSanityBlogPost(sort: { fields: [date], order: DESC }) {
        nodes {
          date
          excerpt
          featuredImage {
            ...ImageWithPreview
          }
          slug {
            current
          }
          title
          tags {
            title
          }
        }
      }
    }
  `);
  return allSanityBlogPost || {};
};
