import React, { useEffect, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Hooks
import { usePadTop, usePadBottom } from '../../hooks';

export const BlockWrapper = ({
  children,
  className,
  paddingTop,
  paddingBottom,
  slug,
  lightTheme,
}) => {
  const containerRef = useRef(null);

  const padTop = usePadTop(paddingTop);
  const padBottom = usePadBottom(paddingBottom);

  const switchTheme = (isLight) => {
    if (isLight) {
      document.documentElement.classList.add('themeLight');
    } else {
      document.documentElement.classList.remove('themeLight');
    }
  };

  useEffect(() => {
    ScrollTrigger.create({
      trigger: containerRef.current,
      start: 'top center',
      end: () => `bottom`,
      onEnter: () => switchTheme(lightTheme),
      onEnterBack: () => switchTheme(lightTheme),
    });
  }, []);

  return (
    <section
      className={`${className} ${padTop} ${padBottom} `}
      id={slug && slug.current}
      ref={containerRef}>
      {children}
    </section>
  );
};
