import React, { useEffect, useRef, Fragment } from 'react';
import { gsap } from 'gsap';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import cx from 'classnames';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import PartnersCarousel from '../components/blockZone/blocks/partnersCarousel';
import { useScrollContext } from '../components/layout';
import { PageMeta } from '../components/pageMeta';
import { useBlogQuery } from '../GraphQl/useBlogQuery';
import { BlogCard } from '../components/blog/blogCard';

import ArrowSide from '../svg/arrow-side.svg';
import ArrowDown from '../svg/arrow-down.svg';

const OtherBlogs = ({ otherBlogs }) => {
  return (
    <div className='px-gutter md:mx-16 grid grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-12 pb-24 md:pb-64 pt-12 md:pt-24'>
      {otherBlogs.map((blog, i) => (
        <BlogCard key={i} {...blog} />
      ))}
    </div>
  );
};

const FeaturedBlog = ({ pageTitle, featuredBlog }) => {
  const { featuredImage, title, slug, excerpt } = featuredBlog;

  const [hover, setHover] = React.useState(false);

  ///fade in
  const fcParentRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    gsap.from(titleRef.current, {
      opacity: 0,
      duration: 0.8,
      yPercent: 40,
      delay: 2,
      scrollTrigger: {
        trigger: fcParentRef.current,
        start: 'top center+=100',
        toggleActions: 'play none none reverse',
      },
    });
  }, []);

  return (
    <div
      ref={fcParentRef}
      className='pt-44 pb-48 text-center relative flex flex-col items-center'>
      <h1 ref={titleRef} className='text-4xl md:text-6xl px-gutter'>
        {pageTitle}
      </h1>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className='mt-28 grid grid-cols-14 gap-y-8 md:px-gutter items-center'>
        <div className='col-start-2 col-end-14 lg:col-start-1 lg:col-end-6 text-left w-full lg:w-96 2xl:w-full flex flex-col gap-y-4'>
          <h2 className='text-3xl md:text-5xl font-display'>{title}</h2>
          <p className='text-base md:text-lg text-blog-gray'>{excerpt}</p>
          <div className='flex items-center'>
            <a
              className='text-xl mr-4 font-light flex gap-x-4 items-center bounce-x'
              href={`/${slug?.current}`}>
              <span>Read article</span>
              <div className='overflow-hidden bounce-x-inview'>
                <ArrowSide />
              </div>
            </a>
          </div>
        </div>
        <div className='col-start-2 lg:col-start-7 col-end-14 lg:col-end-15 cursor-pointer relative'>
          {featuredImage && (
            <a href={`/${slug?.current}`}>
              <Image {...featuredImage} />
            </a>
          )}
          <div
            className={cx(
              'absolute top-0 left-0 w-full h-full bg-black transition-all ease-in-out duration-300 pointer-events-none',
              {
                'bg-opacity-30': !hover,
                'bg-opacity-0': hover,
              },
            )}
          />
        </div>
      </div>

      <div className='absolute bottom-5 bounce-y'>
        <ArrowDown />
      </div>
    </div>
  );
};

const PortalTemplate = ({ data: { portal } }) => {
  const { scrollDefined } = useScrollContext();

  const { title, meta } = portal || {};
  const { nodes } = useBlogQuery();

  const otherBlogs = nodes.slice(1);

  return (
    <Fragment>
      <PageMeta {...meta} />
      {scrollDefined && (
        <Fragment>
          <FeaturedBlog pageTitle={title} featuredBlog={nodes[0]} />
          <OtherBlogs otherBlogs={otherBlogs} />
          <PartnersCarousel heading='Proudly working with' />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PortalTemplate;

export const portalQuery = graphql`
  query standardBlogPortalQuery($slug: String!) {
    portal: sanityBlogPortal(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      title
    }
  }
`;
