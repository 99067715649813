import React, { useEffect, useRef } from 'react';
import { usePartnerQuery } from '../../../GraphQl/usePartnerQuery';
import { gsap } from 'gsap';
import Image from 'gatsby-plugin-sanity-image';

import { BlockWrapper } from '../blockWrapper';

const PartnerItem = React.forwardRef((props, ref) => {
  const { logo, website } = props;
  return (
    <a href={website} className='px-2' ref={ref}>
      <div className='w-40 mx-auto lg:w-64'>
        {logo && logo.asset && (
          <Image {...logo} className='w-full' width={600} />
        )}
      </div>
    </a>
  );
});

const PartnersCarousel = ({ heading, blockConfig }) => {
  const { nodes } = usePartnerQuery();

  const carouselOne = useRef(null);
  const carouselTwo = useRef(null);
  const headingRef = useRef(null);
  const parentRef = useRef(null);
  const partnersWrapper = useRef(null);

  useEffect(() => {
    // Carousel One
    gsap.fromTo(
      carouselOne.current,
      { xPercent: 0 },
      { xPercent: -100, duration: 100, repeat: -1, ease: 'linear' },
    );

    gsap.fromTo(
      carouselTwo.current,
      { xPercent: 100 },
      { xPercent: 0, duration: 100, repeat: -1, ease: 'linear' },
    );

    const tl = gsap.timeline({
      defaults: { duration: 2, ease: 'power4.out' },
      scrollTrigger: {
        trigger: parentRef.current,
        start: 'top center+=100',
        toggleActions: 'play none none reverse',
      },
    });

    tl.from(headingRef.current, {
      duration: 1,
      yPercent: 20,
      opacity: 0,
      ease: 'power4.out',
    }).to(partnersWrapper.current, {
      opacity: 1,
      ease: 'power4.out',
    });
  }, []);

  return (
    <BlockWrapper {...blockConfig}>
      <div className='py-20' ref={parentRef}>
        <div className='px-gutter text-center'>
          <div className='overflow-hidden'>
            <h2
              className='text-3xl font-display leading-relaxed  lg:leading-relaxed lg:text-5xl heading'
              ref={headingRef}>
              {heading}
            </h2>
          </div>
        </div>
        <div
          className='w-full overflow-hidden h-40 relative my-12 lg:h-64 lg:top-4 opacity-0'
          ref={partnersWrapper}>
          {/* Carousel One */}
          <div
            className='flex absolute top-4 left-0 items-start'
            ref={carouselOne}>
            {nodes.map((item) => (
              <PartnerItem {...item} />
            ))}
          </div>
          {/* Carousel Two */}
          <div
            className='absolute top-10 left-0 hidden items-start lg:flex'
            ref={carouselTwo}>
            {nodes.map((item) => (
              <PartnerItem {...item} />
            ))}
          </div>
        </div>
      </div>
    </BlockWrapper>
  );
};

export default PartnersCarousel;
