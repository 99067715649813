import React from 'react';
import Image from 'gatsby-plugin-sanity-image';
import cx from 'classnames';

import ArrowSide from '../../svg/arrow-side.svg';

export const BlogCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  otherBlogs,
}) => {
  const [hover, setHover] = React.useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={otherBlogs ? 'w-[300px] md:w-[400px] xl:w-[550px]' : 'w-full'}
      style={{ flex: '0 0 auto' }}>
      <div className='relative cursor-pointer aspect-w-4 aspect-h-3'>
        {featuredImage && (
          <a href={`/${slug?.current}`}>
            <Image
              {...featuredImage}
              className='absolute top-0 left-0 w-full h-full object-cover'
            />
          </a>
        )}
        <div
          className={cx(
            'absolute top-0 left-0 w-full h-full bg-black transition-all ease-in-out duration-300 pointer-events-none',
            {
              'bg-opacity-30': !hover,
              'bg-opacity-0': hover,
            },
          )}
        />
      </div>
      <div className='mt-8 md:mt-12 flex flex-col gap-y-4'>
        <h2 className='text-3xl md:text-5xl font-display'>{title}</h2>
        <p className='text-base md:text-lg text-blog-gray'>{excerpt}</p>
        <div className='flex items-center'>
          <a
            className='text-xl mr-4 font-light bounce-x flex gap-x-4 items-center'
            href={`/${slug?.current}`}>
            Read article
            <div className='overflow-hidden'>
              <ArrowSide />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
