import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const usePartnerQuery = () => {
  const { allSanityPartner } = useStaticQuery(graphql`
    query {
      allSanityPartner {
        nodes {
          partnerName
          logo {
            ...ImageWithPreview
          }

          website
        }
      }
    }
  `);
  return allSanityPartner || {};
};
